import Model, { Request } from '../model'
import APIObject from '../object'
import Enum from '../enums'

import Reservation from './reservation'
import Customer from './customer'
import ReservationCustomer from './reservationCustomer'

export default class Course extends Model {
  static modelName () {
    return 'course'
  }

  objectID () {
    return this.idCourse
  }

  relations () {
    return {
      
    }
  }

  listRuns () {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/runs`

    return this.constructor.requestList(Request.get(url), CourseRun)
  }

  getRun (idCourseRun) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/runs/${idCourseRun}`

    return this.constructor.requestItem(Request.get(url), CourseRun)
  }

  createRun (data) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/runs`

    return this.constructor.requestItem(Request.post(url, data), CourseRun)
  }

  updateRun (data) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/runs/${data.idCourseRun}`

    return this.constructor.requestItem(Request.put(url, data), CourseRun)
  }

  deleteRun (idCourseRun) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/runs/${idCourseRun}`

    return this.constructor.requestItem(Request.delete(url), CourseRun)
  }

  addCustomer ({
    courseRun,
    idCustomer,
    confirm = true
  }) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/addCustomer/${courseRun.idCourseRun}`

    return this.constructor.requestItem(Request.put(url, {
      idCustomer,
      confirm: confirm ? 1 : 0
    }), CustomerCourse)
  }

  addPhoto (image, options) {
    const url = this.constructor.modelBaseURL() + '/addPhoto?id=' + this.objectID()

    const formData = new FormData()
    formData.append('image', image)

    const request = Request.post(url, formData)
    request.options = options

    return this.constructor.requestItem(request, this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  deletePhoto () {
    const url = this.constructor.modelBaseURL() + '/deletePhoto?id=' + this.objectID()
    return this.constructor.requestItem(Request.get(url), this.constructor).then(this.updateSelf(res => this.updateSelf(res)))
  }

  get label () {
    return this.displayName
  }

  get image () {
    if (this.hasImage) {
      return this.images.square
    }

    return null
  }

  get initials () {
    return ((this.name ? this.name.charAt(0) : '')).toUpperCase()
  }
}

export class CourseRun extends Model {
  static modelName () {
    return 'courseRun'
  }

  objectID () {
    return this.idCourseRun
  }

  relations () {
    return {
      makeupConfig: { type: CourseRunMakeupConfig },
      paymentConfig: { type: CourseRunPaymentConfig },
      partialConfig: { type: CourseRunPartialConfig },
      reservations: { type: CourseRunReservation },
      course: { type: Course },
    }
  }

  customers () {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/customers`

    return this.constructor.requestList(Request.get(url), CourseRunCustomer)
  }

  static upcoming () {
    const url = this.modelBaseURL() + `/upcoming`

    return this.requestList(Request.get(url), CourseRun)
  }

  addReservations (reservations) {
    const url = this.constructor.modelBaseURL() + `/${this.objectID()}/addReservations`

    return this.constructor.requestItem(Request.post(url, {reservations}), CourseRun)
  }
}

export class CustomerCourse extends Model {
  static modelName () {
    return 'customerCourse'
  }

  objectID () {
    return this.idCustomerCourse
  }

  relations () {
    return {
      course: { type: Course },
      courseRun: { type: CourseRun },
      customer: { type: Customer },
      reservations: { type: CustomerCourseReservation },
    }
  }
}

export class CustomerCourseReservation extends APIObject {
  relations () {
    return {
      reservation: { type: Reservation },
      reservationCustomer: { type: ReservationCustomer },
      makeupReservationCustomer: { type: ReservationCustomer },
    }
  }
}

export class CourseRunCustomer extends APIObject {
  relations () {
    return {
      customer: { type: Customer },
    }
  }
}

export class CourseRunReservation extends APIObject {
  relations () {
    return {
      reservation: { type: Reservation },
      courseRun: { type: CourseRun },
    }
  }
}


export const CourseRunMakeupMode = new Enum({
  none: { value: 0 },
  any_service: { value: 1 },
  any_run: { value: 2 },
  select_runs: { value: 2 },
})

export class CourseRunMakeupConfig extends APIObject {
  relations () {
    return {
      mode: { type: CourseRunMakeupMode },
    }
  }
}

export class CourseRunPaymentConfig extends APIObject {
  relations () {
    return {
      mode: { type: CourseRunPaymentMode },
    }
  }
}

export const CourseRunPaymentMode = new Enum({
  full: { value: 0 },
  instalments: { value: 1 }
})

export const CourseRunPartialMode = new Enum({
  none: { value: 0 },
  late: { value: 1 },
  select: { value: 2 },
})

export class CourseRunPartialConfig extends APIObject {
  relations () {
    return {
      mode: { type: CourseRunPartialMode },
    }
  }
}